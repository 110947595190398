import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select"]

  enableAddRecipientButton(){
    var checkedEle = $('.slack-recipient-check:not(:disabled):checked').length
    if (checkedEle > 0) {
      $('#addRecipientButton').removeClass( "cursor-not-allowed" )
      $('#addRecipientTooltip').removeAttr('x-show.transition.origin.top');
    } else {
      $('#addRecipientButton').addClass( "cursor-not-allowed" )
      $('#addRecipientTooltip').attr('x-show.transition.origin.top', 'tooltip');
    }
  }

  adddRecipient(){
    var selectedRecipient = $('.slack-recipient-check:not(:disabled):checked')
    var recipients = [];
    $.each(selectedRecipient, function( index, value ) {
      var values = $(this).parent().siblings();
      var firstName = values[0].textContent;
      var lastname = values[1].textContent;
      var email = values[2].textContent;
      recipients.push({
        firstName: firstName,
        lastname: lastname,
        email: email
      })
    });
    $.ajax({
      type: "POST",
      url: "/slack_integrations/add_recipients",
      data: {
        recipients: recipients
      },
      success: (data) => {
      },
    });
  }

  selectAll(){
    var target = this.selectTarget.checked
    var table = this.selectTarget.closest("table");
    var inputs = $(table).find('input:not(:disabled)');
    $.each(inputs, function( index, value ) {
      $(this).attr('checked', target);
    })
    this.enableAddRecipientButton();
  }
}