$(document).on("turbo:load", function () {
  $("body").on("change", "select[name='survey[template]']", function () {
    let template = $(this).val();
    let templates = {
      enps: {
        1: {
          question: `Would you recommend working at ${$(this).data(
            "company"
          )} to a friend?`,
          acts_as: "scale",
        },
        2: {
          question: "What is the main reason for your score? (Optional)",
          acts_as: "text box",
        },
      },
      leadership: {
        1: {
          question: "How much do you trust the leaders of this organization?",
          acts_as: "scale",
        },
        2: {
          question:
            "What level of confidence have you in the leadership team of this organization?",
          acts_as: "scale",
        },
        3: {
          question:
            "How confident are you in the direction your manager is taking you?",
          acts_as: "scale",
        },
        4: {
          question:
            "Do you believe the leadership team takes your feedback seriously?",
          acts_as: "text box",
        },
        5: {
          question: "Do you feel like the leadership team here is transparent?",
          acts_as: "text box",
        },
        6: {
          question:
            "Does our leadership team contribute to a positive work culture?",
          acts_as: "scale",
        },
      },
      culture: {
        1: {
          question: "The culture of this is a good fit for me.",
          acts_as: "scale",
        },
        2: {
          question: "What three words would you use to describe our culture?",
          acts_as: "text box",
        },
        3: {
          question: "Do you feel like coworkers give each other respect here?",
          acts_as: "scale",
        },
        4: {
          question:
            "Do you believe we authentically live by our organizational values?",
          acts_as: "scale",
        },
        5: {
          question: "What is our company’s greatest weakness?",
          acts_as: "text box",
        },
        6: {
          question: "What is our company’s greatest strength?",
          acts_as: "text box",
        },
        7: {
          question:
            "If you could change one thing about your job or workplace, what would it be?",
          acts_as: "text box",
        },
      },
      organization: {
        1: {
          question:
            "With your eyes closed, can you recite our organization’s values?",
          acts_as: "text box",
        },
        2: {
          question:
            "The values of this organization are closely aligned with my own values.",
          acts_as: "scale",
        },
        3: {
          question:
            "I am clear on the goals and objectives of the organization.",
          acts_as: "scale",
        },
        4: {
          question:
            "How aware are you of how the organization is performing versus its goals?",
          acts_as: "scale",
        },
        5: {
          question:
            "The health and well-being of employees is promoted in this organization.",
          acts_as: "scale",
        },
        6: {
          question:
            "How informed are you about what is going on in the organization?",
          acts_as: "scale",
        },
        7: {
          question:
            "What can we do to do a better job of informing employees of changes?",
          acts_as: "text box",
        },
        8: {
          question:
            "What will continue to make us successful as an organization?",
          acts_as: "text box",
        },
      },
      managers: {
        1: {
          question: "My manager is open and honest with me.",
          acts_as: "scale",
        },
        2: {
          question: "My manager makes timely and good decisions.",
          acts_as: "scale",
        },
        3: {
          question: "My manager treats me with respect.",
          acts_as: "scale",
        },
        4: {
          question: "My manager cares about my development.",
          acts_as: "scale",
        },
        5: {
          question:
            "Is your manager responsive to your ideas, requests and suggestions?",
          acts_as: "text box",
        },
        6: {
          question:
            "Does you manager make you feel like a valued part of the team?",
          acts_as: "text box",
        },
        7: {
          question: "Does your manager handle disagreements professionally?",
          acts_as: "text box",
        },
        8: {
          question:
            "What level of confidence have you in the integrity of your manager?",
          acts_as: "scale",
        },
        9: {
          question:
            "How comfortable do you feel giving feedback to your manager?",
          acts_as: "scale",
        },
        10: {
          question:
            "My manager communicates a clear picture of my team’s direction.",
          acts_as: "scale",
        },
      },
      employee_engagement: {
        1: {
          question: "Do you have fun at work?",
          acts_as: "scale",
        },
        2: {
          question: "I care about the future of this organization.",
          acts_as: "scale",
        },
        3: {
          question:
            "How frequently do you receive recognition for your manager?",
          acts_as: "scale",
        },
        4: {
          question:
            "The last time you accomplished a big project, did you receive any recognition?",
          acts_as: "multiple choice",
        },
        5: {
          question:
            "What is your preferred method for receiving relevant company news?",
          acts_as: "multiple choice",
        },
        6: {
          question:
            "I am willing to put in extra effort on behalf of the organization.",
          acts_as: "scale",
        },
        7: {
          question: "What is your favorite thing about your job or workplace?",
          acts_as: "text box",
        },
      },
      employee_satisfaction: {
        1: {
          question:
            "How happy and satisfied are you to work in this organization?",
          acts_as: "scale",
        },
        2: {
          question: "Do you feel valued at work?",
          acts_as: "scale",
        },
        3: {
          question: "Do you find your work meaningful?",
          acts_as: "scale",
        },
        4: {
          question: "I have a sense of pride in working here.",
          acts_as: "scale",
        },
        5: {
          question: "Would you refer someone to work here?",
          acts_as: "multiple choice",
        },
        6: {
          question:
            "Hypothetically, if you were to quit tomorrow, what would your reason be?",
          acts_as: "text box",
        },
        7: {
          question: "Does your team inspire you to do your best work?",
          acts_as: "multiple choice",
        },
        8: {
          question:
            "Are you inspired by the purpose and mission of our company?",
          acts_as: "multiple choice",
        },
      },
      job_role: {
        1: {
          question: "I am clear on what is expected of me.",
          acts_as: "scale",
        },
        2: {
          question: "I am in a role that makes good use of my skills.",
          acts_as: "scale",
        },
        3: {
          question:
            "I have all the information, knowledge, and skills that I need to do my job well.",
          acts_as: "scale",
        },
        4: {
          question:
            "I am provided with the tools and resources I need to do my job.",
          acts_as: "scale",
        },
        5: {
          question:
            "If you were given the chance, would you reapply to your current role? Why?",
          acts_as: "text box",
        },
        6: {
          question: "I influence and input the goals that I am working on.",
          acts_as: "scale",
        },
        7: {
          question: "I am given an appropriate level of responsibility.",
          acts_as: "scale",
        },
        8: {
          question:
            "I regularly have the freedom to make decisions in my role that are in the best interest of the organization.",
          acts_as: "scale",
        },
        9: {
          question:
            "How aware are you of how your role contributes to the success of the organization?",
          acts_as: "scale",
        },
        10: {
          question:
            "There are sufficient employees in our department to ensure that we can achieve our deadlines and goals",
          acts_as: "multiple choice",
        },
      },
      career_growth: {
        1: {
          question:
            "How satisfied are you that you are personally developing in your role?",
          acts_as: "scale",
        },
        2: {
          question:
            "Do you have a clear understanding of your career or promotion path?",
          acts_as: "scale",
        },
        3: {
          question: "Do you foresee yourself working here one year from now?",
          acts_as: "multiple choice",
        },
        4: {
          question:
            "Do you receive ample learning opportunities at your current position?",
          acts_as: "scale",
        },
        5: {
          question:
            "Do you believe you will be able to reach your full potential here?",
          acts_as: "text box",
        },
        6: {
          question:
            "My manager has spoken to me about my development during the last 6 months.",
          acts_as: "multiple choice",
        },
        7: {
          question:
            "I expect to continue to develop in my current role over the next 12 months.",
          acts_as: "scale",
        },
        8: {
          question:
            "There is somebody in this organization that is encouraging my development.",
          acts_as: "scale",
        },
      },
      salary_and_benefits: {
        1: {
          question:
            "I feel I am paid fairly for the work I do and that my salary compares favorably with what I could get elsewhere.",
          acts_as: "scale",
        },
        2: {
          question: "I am satisfied with my overall compensation",
          acts_as: "scale",
        },
        3: {
          question: "How satisfied are you with our current benefits package?",
          acts_as: "scale",
        },
        4: {
          question: "Which benefits do you like the most?",
          acts_as: "text box",
        },
        5: {
          question: "Which benefits do you like the least?",
          acts_as: "text box",
        },
        6: {
          question: "How satisfied are you with the perks you are receiving?",
          acts_as: "scale",
        },
        7: {
          question:
            "I know about the available benefits in this Organization and I feel they are of value to me.",
          acts_as: "scale",
        },
        8: {
          question:
            "In this organization, there are opportunities to attain extra financial, and other rewards, for an excellent performance.",
          acts_as: "scale",
        },
      },
      work_life_balance: {
        1: {
          question: "How satisfied are you with your current work hours?",
          acts_as: "scale",
        },
        2: {
          question: "How often do you work overtime?",
          acts_as: "multiple choice",
        },
        3: {
          question:
            "How satisfied are you with your current work-life balance?",
          acts_as: "scale",
        },
        4: {
          question:
            "What level of flexibility are you given to manage your work-life balance?",
          acts_as: "scale",
        },
        5: {
          question: "What can we do to give you a better work-life balance?",
          acts_as: "text box",
        },
        6: {
          question: "I can effectively manage my work-life balance.",
          acts_as: "scale",
        },
        7: {
          question: "Have you missed a person event because of work?",
          acts_as: "multiple choice",
        },
        8: {
          question:
            "Could you benefit from offloading some of your tasks to another employee?",
          acts_as: "multiple choice",
        },
        9: {
          question: "How often do you work on the weekend?",
          acts_as: "multiple choice",
        },
        10: {
          question: "How many hours do you sleep on an average work night?",
          acts_as: "scale",
        },
      },
      onboarding: {
        1: {
          question: "Overall, how well do you understand your role, including the responsibilities of your job?",
          acts_as: "scale",
        },
        2: {
          question:
            "How accurately was your role described to you during your interviews (i.e., Are you doing what you expected you’d be doing)",
          acts_as: "scale",
        },
        3: {
          question:
            "How challenging would you say your current role is?",
          acts_as: "scale",
        },
        4: {
          question:
            "How interested are you in your current role?",
          acts_as: "scale",
        },
        5: {
          question:
            "How satisfied or dissatisfied are you with the training that you have received for your new role?",
          acts_as: "scale",
        },
        6: {
          question:
            "How happy or unhappy are you with this company as a place to work?",
          acts_as: "scale",
        },
        7: {
          question:
            "How relevant have your role-based conversations with your manager been?",
          acts_as: "scale",
        },
        8: {
          question:
            "How likely are you to be working at this company two years from today?",
          acts_as: "scale",
        },
        9: {
          question:
            "What feedback do you have for us on your onboarding experience?",
          acts_as: "text box",
        },
      },
      remote_work: {
        1: {
          question:
            "How satisfied are you with the level of your productivity while working remotely?",
          acts_as: "scale",
        },
        2: {
          question: "Are the remote working tools meeting your needs?",
          acts_as: "multiple choice",
        },
        3: {
          question:
            "What steps are you taking to protect your physical health as you work remotely?",
          acts_as: "text box",
        },
        4: {
          question:
            "Have you any tips that have helped you to achieve a healthy balance as you work remotely?",
          acts_as: "text box",
        },
        5: {
          question:
            "Please explain your ideal work environment (hybrid, remote, at the office) and why.",
          acts_as: "text box",
        },
        6: {
          question:
            "Imagine total flexibility. How many days would you prefer to work in our office, if any?",
          acts_as: "multiple choice",
        },
        7: {
          question:
            "I am confident my manager will support our future remote or flexible work policy.",
          acts_as: "scale",
        },
      },
      diversity: {
        1: {
          question:
            "Our organization value diversity.",
          acts_as: "scale",
        },
        2: {
          question:
            "Our leadership understands the importance of diversity for our future success.",
          acts_as: "scale",
        },
        3: {
          question:
            "Our company spends a lot of time in making our company teams diverse.",
          acts_as: "scale",
        },
        4: {
          question:
            "My organization is a safe environment for other social groups, ethnicities and/or races.",
          acts_as: "scale",
        },
        5: {
          question:
            "I feel comfortable talking about issues like racism and sexism with my team.",
          acts_as: "scale",
        },
        6: {
          question:
            "My organization provides an environment for an open expression of ideas, opinions and beliefs.",
          acts_as: "scale",
        },
        7: {
          question:
            "My organization clearly communicates information about its diversity goals.",
          acts_as: "scale",
        },
        8: {
          question:
            "How often have you experienced any kind of bias (direct or indirect) in company meetings?",
          acts_as: "scale",
        },
        9: {
          question:
            "How often have you been the subject of, heard or witnessed racial, ethnic and gender-based jokes?",
          acts_as: "scale",
        },
        10: {
          question:
            "Our leadership is prepared to manage a culturally diverse workforce.",
          acts_as: "scale",
        },
      },
      equity: {
        1: {
          question:
            "How equitable are opportunities for people of minority backgrounds to advance their careers?",
          acts_as: "scale",
        },
        2: {
          question:
            "I can voice my opinion or address people directly when I experience or witness an issue without fear of negative consequences.",
          acts_as: "scale",
        },
        3: {
          question:
            "I trust my organization to be fair to all employees.",
          acts_as: "scale",
        },
        4: {
          question:
            "I am included in decisions that affect my work.",
          acts_as: "scale",
        },
        5: {
          question:
            "My career progress is not threatened if I report a diversity, equity or inclusion issue I witnessed or experienced.",
          acts_as: "scale",
        },
        6: {
          question:
            "Training programs that promote diversity, equity and inclusion are useful and accessible to everyone.",
          acts_as: "scale",
        },
        7: {
          question:
            "People of different backgrounds are encouraged to apply for higher positions in the company.",
          acts_as: "scale",
        },
        8: {
          question:
            "People of different backgrounds are treated fairly in the internal promotion process.",
          acts_as: "scale",
        },
      },
      inclusion: {
        1: {
          question:
            "My unique background and identity is valued by my organization.",
          acts_as: "scale",
        },
        2: {
          question:
            "I feel a sense of belonging in my team and organization.",
          acts_as: "scale",
        },
        3: {
          question:
            "I feel respected by your colleagues and manager.",
          acts_as: "scale",
        },
        4: {
          question:
            "I understand the process for my career development in this company.",
          acts_as: "scale",
        },
        5: {
          question:
            "I feel like I can be myself around my colleagues.",
          acts_as: "scale",
        },
        6: {
          question:
            "My organization is ready to deal with cases of sexual harassment.",
          acts_as: "scale",
        },
        7: {
          question:
            "My organization is ready to deal with cases of racial discrimination.",
          acts_as: "scale",
        },
        8: {
          question:
            "When colleagues witness bias, they readily call it out.",
          acts_as: "scale",
        },
        9: {
          question:
            "The people I work with treat each other with respect.",
          acts_as: "scale",
        },
        10: {
          question:
            "My company values equally employees of different ages.",
          acts_as: "scale",
        },
      },
      follow_up: {
        1: {
          question:
            "My manager shared the results of the last survey with our team.",
          acts_as: "scale",
        },
        2: {
          question: "I noticed positive change as a result of the last survey.",
          acts_as: "scale",
        },
        3: {
          question:
            "What additional feedback do you have regarding our engagement survey follow-up?",
          acts_as: "text box",
        },
        4: {
          question:
            "Is there something else you think we should ask you in this survey?",
          acts_as: "text box",
        },
        5: {
          question: "What are some things we are doing great here?",
          acts_as: "text box",
        },
        6: {
          question: "What are some things we can continue to improve on?",
          acts_as: "text box",
        },
      },
      training: {
        1: {
          question:
            "I have all the resources I need to do my job successfully.",
          acts_as: "scale",
        },
        2: {
          question: "My goals are clearly defined.",
          acts_as: "scale",
        },
        3: {
          question:
            "I have a clear understanding of my career path and promotion plan.",
          acts_as: "scale",
        },
        4: {
          question:
            "I am happy with my career path and promotion plan.",
          acts_as: "scale",
        },
        5: {
          question: "So far, I am satisfied with my job.",
          acts_as: "scale",
        },
        6: {
          question: "I feel welcomed by my team.",
          acts_as: "scale",
        },
        7: {
          question: `I feel welcomed at ${$(this).data("company")}`,
          acts_as: "scale",
        },
        8: {
          question: `I feel like I fit in ${$(this).data("company")} company culture.`,
          acts_as: "scale",
        },
        9: {
          question: "Overall, I am satisfied with the onboarding process.",
          acts_as: "scale",
        },
        10: {
          question:
            "What do you like the most about our onboarding process?",
          acts_as: "text box",
        },
        11: {
          question:
            "Is there something that you don’t like about our onboarding process?",
          acts_as: "text box",
        },
        12: {
          question:
            "What could we change or add to improve our onboarding process?",
          acts_as: "text box",
        },
        13: {
          question:
            "Do you feel like you have all the tools and resources to perform your job successfully?",
          acts_as: "text box",
        },
        14: {
          question:
            "What is the biggest challenge you’ve faced so far?",
          acts_as: "text box",
        },
        15: {
          question:
            "What can we do to help you do your job even better?",
          acts_as: "text box",
        },
      },
      exit: {
        1: {
          question:
            "What was your main reason for leaving our company?",
          acts_as: "multiple choice",
          choices: ['Better base salary', 'Better benefits package', 'Promotion in new role', 'Unique opportunity', 'Relocation', 'Career change', 'Conflict with manager', 'Conflict within the company', 'Other']
        },
        2: {
          question: "How well did this role fit your skill set?",
          acts_as: "scale",
        },
        3: {
          question: "How reasonable or unreasonable was the workload for this role?",
          acts_as: "scale",
        },
        4: {
          question: "How accurately was the role described to you before you joined the organization?",
          acts_as: "scale",
        },
        5: {
          question: "How good or bad was the overall quality of the physical/remote environment you were working in?",
          acts_as: "scale",
        },
        6: {
          question: "How fairly or unfairly do you think this company treats all of its employees?",
          acts_as: "scale",
        },
        7: {
          question: "How fair did you feel your total compensation package (pay + benefits + any equity) was compared to other organizations?",
          acts_as: "scale",
        },
        8: {
          question: "Did you feel your pay matched your performance in this role?",
          acts_as: "scale",
        },
        9: {
          question: "Overall, how good or bad was your experience working for our company?",
          acts_as: "scale",
        },
        10: {
          question: "On a scale from 0-10, how likely are you to recommend our company to a friend or colleague?",
          acts_as: "scale",
        },
        11: {
          question:
            "Do you have any additional feedback about our company, your role, or factors that contributed to your decision?",
          acts_as: "text box",
        },
      },
      esi: {
        1: {
          question:
            "How satisfied are you with your current workplace?",
          acts_as: "scale",
        },
        2: {
          question: "How well does your current workplace meet your expectations?",
          acts_as: "scale",
        },
        3: {
          question: "How close is your current workplace to the ideal one?",
          acts_as: "scale",
        },
      },
      eei: {
        1: {
          question:
            `I am proud to work for ${$(this).data("company")}.`,
          acts_as: "scale",
        },
        2: {
          question: `I would recommend ${$(this).data("company")} as a great place to work.`,
          acts_as: "scale",
        },
        3: {
          question: "I rarely think about looking for a job at another company.",
          acts_as: "scale",
        },
        4: {
          question: `I see myself still working at ${$(this).data("company")} in two years’ time.`,
          acts_as: "scale",
        },
        5: {
          question: `${$(this).data("company")} motivates me to go beyond what I would in a similar role elsewhere.`,
          acts_as: "scale",
        },
        6: {
          question: `The leaders at ${$(this).data("company")} keep people informed about what is happening.`,
          acts_as: "scale",
        },
        7: {
          question: "My manager is a great role model for employees.",
          acts_as: "scale",
        },
        8: {
          question: `The leaders at ${$(this).data("company")} have communicated a vision that motivates me.`,
          acts_as: "scale",
        },
        9: {
          question: "I have access to the things I need to do my job well.",
          acts_as: "scale",
        },
        10: {
          question: "I have access to the learning and development I need to do my job well.",
          acts_as: "scale",
        },
        11: {
          question: "Most of the systems and processes here support us getting our work done effectively.",
          acts_as: "scale",
        },
        12: {
          question: "I know what I need to do to be successful in my role.",
          acts_as: "scale",
        },
        13: {
          question: "I receive appropriate recognition when I do good work.",
          acts_as: "scale",
        },
        14: {
          question: "Day-to-day decisions here demonstrate that quality and improvement are top priorities.",
          acts_as: "scale",
        },
        15: {
          question: "My manager (or someone in management) has shown a genuine interest in my career aspirations.",
          acts_as: "scale",
        },
        16: {
          question: "I believe there are good career opportunities for me at this company.",
          acts_as: "scale",
        },
        17: {
          question: "This is a great company for me to make a contribution to my development.",
          acts_as: "scale",
        },
        18: {
          question: "Are there some things we are doing great here?",
          acts_as: "text box",
        },
        19: {
          question: "Are there some things we are not doing so great here?",
          acts_as: "text box",
        },
        20: {
          question: "Is there something else you think we should have asked you in this survey?",
          acts_as: "text box",
        },
      },
    };

    $("#questions").find(".destroy-btn").trigger("click");
    $("#questions").html('');

    for (var question in templates[template]) {
      $(".add-question").trigger("click");
      new_question = $(".question:last");
      question_template = templates[template][question];

      $(new_question).find(".question-field").val(question_template.question);
      $(new_question).find(".acts-as").val(question_template.acts_as);
      $.each(question_template.choices, function(index, value) {
        $('#questions .add-choice').last().click();
        $('.choices input.border-gray-300').last().val(value);
      });
    }

    if (template == 'enps' || template == 'esi') {
      if ($(this).attr("data-free-user") == undefined) {
        $('#survey_periodicity').html('<option value="">Select one</option><option value="every month">Every month</option><option value="every quarter">Every quarter</option>');
      } else {
        $('#survey_periodicity').html('<option selected="selected" value="every month">Every month</option>');
      }
    } else if (template == 'employee_engagement' || template == 'eei') {
      $('#survey_periodicity').html('<option value="">Select one</option><option value="every month">Every month</option><option value="every quarter">Every quarter</option><option value="every 6 months">Every 6 months</option><option value="every year">Every year</option>');
    } else {
      $('#survey_periodicity').html('<option value="">Select one</option><option value="every week">Every week</option><option value="every 2 weeks">Every 2 weeks</option><option value="every month">Every month</option><option value="every 2 months">Every 2 months</option><option value="every quarter">Every quarter</option><option value="every 2 quarters">Every 2 quarters</option><option value="every year">Every year</option>');
    }

    if (template == 'onboarding' || template == 'training' || template == 'exit') {
      // Recipient field changes
      if ($('#survey_recipient_emails').val().length == 0) {
        $('.recipients-sec').removeClass('hidden');
        $('.recipients-sec .selectize-input').addClass('required');
        $('.recipients-sec input').attr('required', 'required');
      }

      // Frequency field changes
      $('.frequency-sec').addClass('hidden');
      $('.frequency-sec select').removeAttr('required');

      // Department field changes
      $('.department-sec').addClass('hidden');
      $('.department-sec .selectize-input').removeClass('required');
      $('.department-sec input').removeAttr('required');
    } else {
      // Recipient field changes
      $('.recipients-sec').addClass('hidden');
      $('.recipients-sec .selectize-input').removeClass('required');
      $('.recipients-sec input').removeAttr('required');

      // Frequency field changes
      $('.frequency-sec').removeClass('hidden');
      $('.frequency-sec select').attr('required', 'required');

      // Department field changes
      if ($('#survey_department_ids').val().length == 0) {
        $('.department-sec').removeClass('hidden');
        $('.department-sec .selectize-input').addClass('required');
        $('.department-sec input').attr('required', 'required');
      }
    }

    if (template == "enps" || template == "eei" || template == "esi"){
      $('.add-question').addClass('hidden');
      $('#questions input').prop( "readonly", true);
      $('#questions select').prop( "readonly", true);
      $('#questions .destroy-btn ').prop( "readonly", true);
    }else {
      $('.add-question').removeClass('hidden');
      $('#questions input').prop( "readonly", false);
      $('#questions select').prop( "readonly", false);
      $('#questions .destroy-btn ').prop( "readonly", false);
    }

    setInterval(function () {
      $(".acts-as").trigger("click");
    }, 1000);
  });

  $("body").on("click", "[data-action='hide-closest']", function () {
    $(this).closest($(this).data("target")).hide();
    return false;
  });

  $("body").on("click", "input[data-toggle='datetime']", function () {
    // $(this).flatpickr({ dateFormat: "m-d-Y - h:iK", enableTime: true, static: true }).toggle();
    $(this)
      .flatpickr({
        enableTime: true,
        static: true,
        altInput: true,
        altFormat: "Y-m-d H:i K",
        allowInput: true,
        onOpen: function (selectedDates, dateStr, instance) {
          $(instance.altInput).prop("readonly", true);
        },
        onClose: function (selectedDates, dateStr, instance) {
          $(instance.altInput).prop("readonly", false);
          $(instance.altInput).blur();
        },
      })
      .toggle();
  });

  $("body").on("click", "input[data-toggle='date']", function () {
    // $(this).flatpickr({ dateFormat: "m-d-Y", static: true }).toggle();
    $(this).flatpickr({ static: true }).toggle();
  });

  $("body").on("click", "[data-clear]", function () {
    let input = $(this).attr("data-clear");
    $(`#${input}`).val("");
  });

  $("body").on("submit", "#include-ids", function (ev) {
    ev.preventDefault();
    let action = $(this).attr("action");

    $(this).attr("action", action.split("?")[0]);
    action = $(this).attr("action");

    let ids = [];
    $("input:checkbox[name='ids[]']:checked").each(function () {
      ids.push($(this).val());
    });

    $(this).attr("action", action + `?ids=${ids}`);
    ev.currentTarget.submit();
  });

  $("#check-all").on("click", function (e) {
    var table = $(e.target).closest("table");
    $("td input:checkbox", table).prop("checked", this.checked);
  });
});

$(document).on("turbo:submit-end", function (ev) {
  let form = `form#${ev.originalEvent.target.id}`;
  let closeBtn = $(form).find(".openFormCloseBtn");
  if ($(closeBtn).length) {
    if (ev.originalEvent.detail.success) {
      $(closeBtn).click();
      $(form).get(0).reset();
    }
  }
});

addFields = (link, association, content, place) => {
  var newid = new Date().getTime();
  var regexp = new RegExp("new_" + association, "g");
  $(link).prev(place).append(content.replace(regexp, newid));
};
