import { Controller } from "stimulus";

export default class extends Controller {

  demoCookie(e){
    var demo = $(e.currentTarget).data("demo");
    document.cookie = "on_demo="+demo;
    if (demo == false) {
      $('#demo').toggleClass("hidden");
      $('.pointer-events-none').toggleClass("pointer-events-none");
      $('#notificationSection').toggle();
      $('#notificationSection').html('');
    }
  }

  closeDemoTooltip(){
    var demoVal = getCookie('on_demo');
    if ( demoVal == 'true' ) {
      if ($(window).width() >= 640) {
        $('#demoTooltip').toggle();
      } else {
        $('#mobiledemoTooltip').toggle();
      }
    }
  }

  completeDemo(){
    document.cookie = "on_demo=false";
    $('#demoCompleteTooltip').toggleClass("hidden");
    $('.pointer-events-none').toggleClass("pointer-events-none");
    $('#completeDemo').toggleClass("hidden");
  }

  closeDemoCompleteDemo(){
    $('#completeDemo').toggleClass("hidden");
  }
}