import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select"]

  connect(){
    var templates = [['Leadership', 'leadership'], ['Organization', 'organization'], ["Culture", 'culture'], ["Managers", "managers"], ['Employee Satisfaction', 'employee_satisfaction'], ['Job Role', 'job_role'], ['Career Growth', 'career_growth'], ["Salary and Benefits", 'salary_and_benefits'], ["Work-Life Balance", "work_life_balance"], ['Remote Work', 'remote_work'], ['Diversity', 'diversity'], ['Equity', 'equity'], ['Inclusion', 'inclusion'], ['Follow Up', 'follow_up']]
    var templateName = $('#survey_template').data('template');
    $.each(templates, function(index, value) {
      if (templateName == value[1]) {
        $('#survey_template').append('<option selected=selected value='+ value[1] +'>'+value[0]+'</option>');
      } else {
        $('#survey_template').append('<option value='+ value[1] +'>'+value[0]+'</option>');
      }
    })

    var template = $('#survey_template').val();
    if (template == 'onboarding' || template == 'training' || template == 'exit') {
      // Frequency field changes
      $('.frequency-sec .selectize-input').removeClass('required');
      $('.frequency-sec select').removeAttr('required');

      // Department field changes
      $('.department-sec .selectize-input').removeClass('required');
      $('.department-sec input').removeAttr('required');
    } else if (template != "") {
      // Recipient field changes
      $('.recipients-sec .selectize-input').removeClass('required');
      $('.recipients-sec input').removeAttr('required');
    }
  }

  changeTab(e){
    var target = e.currentTarget;
    $(target).addClass( "active bg-gray-100" );
    if (target.text == "Scale Questions") {
      $('#mcqsQues').addClass( "hidden" );
      $('#conversations').addClass( "hidden" );
      $('#scaleQues').removeClass( "hidden" );
      $('.mcqs-tab a').removeClass( "active bg-gray-100" );
      $('.conversation-tab a').removeClass( "active bg-gray-100" );
      $('#filter').removeClass( "hidden" );
    } else if (target.text == "Multiple Choice Questions") {
      $('#conversations').addClass( "hidden" );
      $('#scaleQues').addClass( "hidden" );
      $('#mcqsQues').removeClass( "hidden" );
      $('.scale-tab a').removeClass( "active bg-gray-100" );
      $('.conversation-tab a').removeClass( "active bg-gray-100" );
      $('#filter').addClass( "hidden" );
    } else if (target.text == "Text Questions") {
      $('#scaleQues').addClass( "hidden" );
      $('#mcqsQues').addClass( "hidden" );
      $('#conversations').removeClass( "hidden" );
      $('.scale-tab a').removeClass( "active bg-gray-100" );
      $('.mcqs-tab a').removeClass( "active bg-gray-100" );
      $('#filter').addClass( "hidden" );
    }
  }

  changeRoundFilter(e){
    if($('#round_days').val() != 'custom'){
      e.currentTarget.closest('form').submit();
    }
    else{
      $('.date-range-fields').removeClass("hidden");
    }
  }

  toggleSaveBtnVisibility(){
    $('#saveSurveyBtn').toggleClass('pointer-events-none');
  }
}
