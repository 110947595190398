import { Controller } from "stimulus";

export default class extends Controller {

  setAdditionalRecipients(e){
    $('#company_additional_recipients').val(e.target.value);
    var price_id = $("#additional_recipients option:selected").attr('data-price_id');
    $('#company_stripe_additional_recipients_price_id').val(price_id);
    if (e.target.value == '') {
      $('#additional-section').addClass('hidden');
      $('#totalAmount').text($('.plan-amount').text());
    } else {
      var value = $("#additional_recipients option:selected").text().split('$')[1].split('/')[0]
      var additionalAmount = '$' + value + '/month';
      var planAmount = parseInt($('.plan-amount').text().split('$')[1].split('/')[0])
      var total = parseInt(value) + planAmount
      var totalAmount = '$' + total  + '/month'
      $('#additional-section').removeClass('hidden');
      $('#additionalAmount').text(additionalAmount);
      $('#totalAmount').text(totalAmount);
    }
  }

  updateReviewSection(e){
    var currentPrice = $(e.currentTarget).closest('.relative').children('#planAmount').text();
    var currentPlan = e.currentTarget.nextElementSibling.textContent;
    $('.plan-amount').text(currentPrice);
    $('.plan-type').text(currentPlan);
    if ($('#additional-section').hasClass('hidden')){
      $('#totalAmount').text(currentPrice);
    } else {
      var additionalAmount = parseInt($('#additionalAmount').text().split('$')[1].split('/')[0])
      var planAmount = parseInt($('.plan-amount').text().split('$')[1].split('/')[0])
      var totalAmount = '$' + (planAmount+additionalAmount)  + '/month'
      $('#totalAmount').text(totalAmount);
    }
  }
}