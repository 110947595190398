import { Controller } from "stimulus";

export default class extends Controller {

  connect(){
    if($(window).height() > $("body").height()){
      $("footer").css("position", "fixed");
      $("footer").css("bottom", "0");
      $("footer").css("left", "0");
      $("footer").css("right", "0");
    } else {
      $("footer").css("position", "static");
    }
    $('.trix-button-group--block-tools').remove();
    $('.trix-button--icon-attach').remove();
    $('.trix-button-group--file-tools').remove();
  }

  enablebuttons(e){
    var target = e.currentTarget;
    if (target.value != '' ){
      $('#next-button').removeClass('pointer-events-none');
    }
    else {
      $('#next-button').addClass('pointer-events-none');
    }
  }

  disablebuttons(){
    var turnover_width = Math.ceil($(".turnover-progress-bar").width() / $('.turnover-progress-bar').parent().width() * 100);
    turnover_width = turnover_width + 25 + '%';
    var retention_width = Math.ceil($(".retention-progress-bar").width() / $('.retention-progress-bar').parent().width() * 100);
    retention_width = retention_width + 30 + '%';
    var roi_width = Math.ceil($(".roi-progress-bar").width() / $('.roi-progress-bar').parent().width() * 100);
    roi_width = roi_width + 15 + '%';
    $('.turnover-progress-bar').width(turnover_width);
    $('.retention-progress-bar').width(retention_width);
    $('.roi-progress-bar').width(roi_width);
    if ($('.hidenextbutton').val() != ''){
      $('#next-button').addClass('hidden');
    } else{
      $('#next-button').addClass('pointer-events-none');
    }
  }

  opendialogbox(){
    if ($('input.first_name').filter(function() { return this.value === ''; }).length == 0 && $('input.work_email').filter(function() { return this.value === ''; }).length == 0) {
      $('#authenticationmodal').show();
      $("#authenticationmodal").css("background-color" , "rgba(0, 0, 0, 0.5");
      $("body").css("overflow", "hidden");
    }
  }

  closedialogbox(){
    $('#authenticationmodal').hide();
    $("#authenticationmodal").css("background-color" , "");
    $("body").css("overflow", "");
  }

  changeclapsvalue(e){
    var target = e.target;
    var min = target.min;
    var max = target.max;
    var val = target.value;
    target.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%';
    var slider_width = e.currentTarget.closest('.range-field').value;
    $(e.currentTarget.parentElement.parentElement).find('.claps').val(slider_width);
  }

  changeprogressvalue(e){
    var rangeInput = $(e.target).parents('.claps-input-section').find('input[type="range"]')[0];
    var min = rangeInput.min;
    var max = rangeInput.max;
    var val = $(e.target).closest('.claps').val();
    rangeInput.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%';
    var claps_width = e.currentTarget.closest('.claps').value;
    $(e.target).parents('.claps-input-section').find('.range-field').val(claps_width);
  }
}
