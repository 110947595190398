import { Controller } from "stimulus";

export default class extends Controller {

  connect(){
    $('#article_content h1').addClass('font-medium leading-tight text-5xl mt-0 mb-2');
    $('#article_content h2').addClass('font-medium leading-tight text-4xl mt-0 mb-2');
    $('#article_content h3').addClass('font-medium leading-tight text-2xl mt-0 mb-2');
    $('#article_content p').addClass('mb-3');
    $('#article_content img').addClass('mt-4');
    $('#article_content a').css('color', '#5B21B6');
    $('#article_content ol').addClass('list-decimal ml-5');
    $('#article_content ul').addClass('list-disc ml-5');
    $('#article_content br').remove();
    $('#article_content blockquote').css({"border": "0 solid #ccc", "border-left-width": "0.3em", "margin-left": "0.3em", "padding-left": "0.6em"});
  }

  handleDraftData(e){
    var contentVal = CKEDITOR.instances.article_content.getData();
    $("input[name='article[draft_content]']").val(contentVal);
    var buttons = $('#articleForm :submit').not(e.currentTarget);
    buttons.removeAttr('data-disable-with');
  }
}
