import Rails from "@rails/ujs";
import { Controller } from "stimulus";
import Sortable from "sortablejs";

export default class extends Controller {              
  static targets = [ "toggleable" ]

  connect() {
    var nestedSortables = [].slice.call(document.querySelectorAll('.nested'));
    for (var i = 0; i < nestedSortables.length; i++) {
      new Sortable(nestedSortables[i], {
        group: 'nested',
        animation: 150,
        fallbackOnBody: true,
        swapThreshold: 0.65,
        onEnd: this.end.bind(this),
      });
    }
  }

  end(event) {
    let ids = []
    if ($(event.item).attr('data-url').includes("menu_items")) {
      $('.sortable-menu-items').each(function() {
        ids.push($(this).attr('data-id'));
      });
    } else {
      $('.sortable-menu').each(function() {
        ids.push($(this).attr('data-id'));
      });
    }
    let data = new FormData();
    ids.forEach((id) => data.append("ids[]", id))

    Rails.ajax({
      url: event.item.getAttribute("data-url"),
      type: "PATCH",
      data: data,
    });
  }

  toggle() {
    $(this.toggleableTarget).toggleClass("hidden");
  }

  addMenuItem(){
    $('#error_explanation').remove();
    var item_number = $(".menu_items .grid").length
    $(".menu_items").append('<div class="grid grid-cols-12 gap-4"><div class="sm:col-span-3 col-span-12"><input placeholder="MenuItem" class="px-3 mt-4 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm w-full" type="text" name="menu[menu_items_attributes]['+item_number+'][name]" id="menu_menu_items_attributes_'+item_number+'_name" required="required"></div><div class="sm:col-span-6 col-span-10"><input placeholder="Link" class="px-3 mt-4 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm w-full" type="text" name="menu[menu_items_attributes]['+item_number+'][link]" id="menu_menu_items_attributes_'+item_number+'_link" required="required"></div><div class="sm:col-span-3 col-span-2 sm:mt-5 mt-6"><a class="border-2 border-red-600  mt-3 text-lg sm:w-auto text-base leading-4 text-center p-2 text-red-600 rounded" data-confirm="Are you sure?" data-remote="true" rel="nofollow" data-method="delete" href="/menu_items/inactive?index='+item_number+'">x</a></div></div>');
  }
}
