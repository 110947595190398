import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select"]

  connect() {
    $(this.selectTarget).selectize({
      placeholder: "Add a tag",
      create: function (input, callback) {
        $.ajax({
          method: "POST",
          url: "/tags.json",
          data: { tag: { name: input } },
          success: function (response) {
            // call callback with the new item
            callback({ value: response.id, text: response.name });
          },
        })
      },
    });
  }
}