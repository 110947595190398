import { Controller } from "stimulus";

export default class extends Controller {

  submitFilterForm(e){
    var target = $(e.currentTarget);
    var template = target.data("template");
    var month = target.data("month");
    var year = target.data("year");
    if (template == "eei") {
      var id = "#engagement-filters";
    } else if (template == "enps") {
      var id = "#satisfaction-filters";
    } else if (template == "onboarding") {
      var id = "#onboarding-filters";
    } else if (template == "training") {
      var id = "#training-filters";
    } else if (template == "exit") {
      var id = "#exit-filters";
    }

    var question_filter = $(id + " #question_filter").val();
    var manager_filter = $(id + " #manager_filter").val();
    var survey_filter = $(id + " #survey_filter").val();
    $.ajax({
      method: "GET",
      url: "/filter_data",
      data: {
              template: template,
              month: month,
              year: year,
              manager_filter: manager_filter,
              survey_filter: survey_filter,
              question_filter: question_filter
            },
      success: function (response) {},
    })
  }

  submitForm(e){
    var form = e.currentTarget.closest('form');
    form.dispatchEvent(new Event('submit', {bubbles: true}));
  }

  submitdepartmentForm(e){
    var target = e.currentTarget;
    var score = parseFloat($('.engaged_score').text().split('\n')[5]);
    if (target.value == 'Consumer'){
      this.getIndustryScore(score, 42);
      $('.department-score').text('42%' );
    } else if(target.value == 'Education'){
      this.getIndustryScore(score, 39);
      $('.department-score').text('39%' );
    } else if(target.value == 'Energy'){
      this.getIndustryScore(score, 33);
      $('.department-score').text('33%' );
    } else if(target.value == 'Finance'){
      this.getIndustryScore(score, 40);
      $('.department-score').text('40%' );
    } else if(target.value == 'Healthcare'){
      this.getIndustryScore(score, 41);
      $('.department-score').text('41%' );
    } else if(target.value == 'Manufacturing'){
      this.getIndustryScore(score, 34);
      $('.department-score').text('34%' );
    } else if(target.value == 'Non-Profit'){
      this.getIndustryScore(score, 39);
      $('.department-score').text('39%' );
    } else if(target.value == 'Professional Services'){
      this.getIndustryScore(score, 41);
      $('.department-score').text('41%' );
    } else if(target.value == 'Technology'){
      this.getIndustryScore(score, 44);
      $('.department-score').text('44%' );
    } else {
      $(".industry-score").text('');
      $('.department-score').text('' );
      $('.negative-sign').addClass('hidden' );
      $('.positive-sign').addClass('hidden' );
    }

    if (industryScore > 0) {
      $('.positive-sign').removeClass('hidden' );
      $('.negative-sign').addClass('hidden' );
    } else if(industryScore < 0) {
      $('.negative-sign').removeClass('hidden' );
      $('.positive-sign').addClass('hidden' );
    }
  }

  getIndustryScore(jobiniScore, staticScore){
    if (isNaN(jobiniScore)) {
      $(".industry-score").text('-');
    } else {
      var industryScore = jobiniScore-staticScore+"%";
      $(".industry-score").text(industryScore);
    }
  }

  changeTab(e){
    var target = e.currentTarget;
    if (target.text.split('\n')[0] == "Engagement"){
      $('.Survey-type').text('Employee Engagement' );
      $('.engagement').removeClass('bg-purple-800 text-white' );
      $('.engagement').addClass('bg-white text-gray-900' );
      $('.satisfaction').removeClass('bg-white text-gray-900' );
      $('.lifecycle').removeClass('bg-white text-gray-900' );
      $('.engagement-index').removeClass('hidden' );
      $('.satisfaction-index').addClass('hidden' );
      $('.lifecycle-index').addClass('hidden' );
      $('.satisfaction').addClass('bg-purple-800 text-white' );
      $('.lifecycle').addClass('bg-purple-800 text-white' );
      $('.engagement .tooltip-text').removeClass('text-gray-600' );
      $('.engagement .tooltip-text').addClass('bg-purple-800 text-white' );
      $('.satisfaction .tooltip-text').removeClass('bg-purple-800 text-white' );
      $('.satisfaction .tooltip-text').addClass('bg-white text-gray-600' );
      $('.lifecycle .tooltip-text').removeClass('bg-purple-800 text-white' );
      $('.lifecycle .tooltip-text').addClass('bg-white text-gray-600' );
    } else if(target.text.split('\n')[0] == "Lifecycle"){
      $('.Survey-type').text('Employee Lifecycle' );
      $('.lifecycle').removeClass('bg-purple-800 text-white' );
      $('.lifecycle').addClass( 'bg-white text-gray-900' );
      $('.satisfaction').removeClass( 'bg-white text-gray-900' );
      $('.engagement').removeClass( 'bg-white text-gray-900' );
      $('.lifecycle-index').removeClass('hidden' );
      $('.engagement-index').addClass('hidden' );
      $('.satisfaction-index').addClass('hidden' );
      $('.satisfaction').addClass('bg-purple-800 text-white' );
      $('.engagement').addClass('bg-purple-800 text-white' );
      $('.lifecycle .tooltip-text').removeClass('text-gray-600' );
      $('.lifecycle .tooltip-text').addClass('bg-purple-800 text-white' );
      $('.engagement .tooltip-text').removeClass('bg-purple-800 text-white' );
      $('.engagement .tooltip-text').addClass('bg-white text-gray-600' );
      $('.satisfaction .tooltip-text').removeClass('bg-purple-800 text-white' );
      $('.satisfaction .tooltip-text').addClass('bg-white text-gray-600' );
    } else{
      $('.Survey-type').text('Employee Satisfaction' );
      $('.satisfaction').removeClass('bg-purple-800 text-white' );
      $('.satisfaction').addClass( 'bg-white text-gray-900' );
      $('.lifecycle').removeClass( 'bg-white text-gray-900' );
      $('.engagement').removeClass( 'bg-white text-gray-900' );
      $('.satisfaction-index').removeClass('hidden' );
      $('.engagement-index').addClass('hidden' );
      $('.lifecycle-index').addClass('hidden' );
      $('.lifecycle').addClass('bg-purple-800 text-white' );
      $('.engagement').addClass('bg-purple-800 text-white' );
      $('.satisfaction .tooltip-text').removeClass('text-gray-600' );
      $('.satisfaction .tooltip-text').addClass('bg-purple-800 text-white' );
      $('.engagement .tooltip-text').removeClass('bg-purple-800 text-white' );
      $('.engagement .tooltip-text').addClass('bg-white text-gray-600' );
      $('.lifecycle .tooltip-text').removeClass('bg-purple-800 text-white' );
      $('.lifecycle .tooltip-text').addClass('bg-white text-gray-600' );
    }
  }

  changelifecycle(e){
    var target = e.currentTarget;
    $(target).addClass( "active bg-gray-100" );
    if (target.text == "Training"){
      $('.onboarding').removeClass( "active bg-gray-100" );
      $('.exit').removeClass( "active bg-gray-100" );
      $('.training-questions').removeClass('hidden');
      $('.onboarding-questions').addClass('hidden');
      $('.exit-questions').addClass('hidden');
      $('.scale-qstn').addClass( "active bg-gray-100" );
      $('.text-qstn').removeClass( "active bg-gray-100" );
      $('.survey_scale_qstn').removeClass('hidden');
      $('.survey_text_ques').addClass('hidden');
    } else if(target.text == "Exit"){
      $('.onboarding').removeClass( "active bg-gray-100" );
      $('.training').removeClass( "active bg-gray-100" );
      $('.exit-questions').removeClass('hidden');
      $('.onboarding-questions').addClass('hidden');
      $('.training-questions').addClass('hidden');
      $('.scale-qstn').addClass( "active bg-gray-100" );
      $('.text-qstn').removeClass( "active bg-gray-100" );
      $('.survey_scale_qstn').removeClass('hidden');
      $('.survey_text_ques').addClass('hidden');
    } else if(target.text == "Onboarding"){
      $('.training').removeClass( "active bg-gray-100" );
      $('.exit').removeClass( "active bg-gray-100" );
      $('.onboarding-questions').removeClass('hidden');
      $('.training-questions').addClass('hidden');
      $('.exit-questions').addClass('hidden');
      $('.scale-qstn').addClass( "active bg-gray-100" );
      $('.text-qstn').removeClass( "active bg-gray-100" );
      $('.survey_scale_qstn').removeClass('hidden');
      $('.survey_text_ques').addClass('hidden');
    }
  }

  changequestion(e){
    var target = e.currentTarget;
    $(target).addClass( "active bg-gray-100" );
    if (target.text == "Text Questions"){
      $('.text-qstn').addClass( "active bg-gray-100" );
      $('.scale-qstn').removeClass( "active bg-gray-100" );
      $('.survey_text_ques').removeClass('hidden');
      $('.survey_scale_qstn').addClass('hidden');
    } else{
      $('.text-qstn').removeClass( "active bg-gray-100" );
      $('.scale-qstn').addClass( "active bg-gray-100" );
      $('.survey_scale_qstn').removeClass('hidden');
      $('.survey_text_ques').addClass('hidden');
    }
  }
}
